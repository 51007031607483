import React from 'react';
import Hero from '../Components/Hero';
import Promo from '../Components/Promo';
import Features from '../Components/Features';
import Banner from '../Components/Banner';
import Category from '../Components/Category';
import TabContent from '../Components/TabPromo/TabContent';
import DivideBanner from '../Components/DivideBanner';
import FeatureSection from '../Components/FeatureSection/FeatureSection';
const Home = ({setIsOpen}) => {
    return (
        <>
            <Hero setIsOpen={setIsOpen}/>
            <Banner />
            <Promo />
            <Category />
            <Features />
            <TabContent />
            <FeatureSection />
            <DivideBanner />
        </>
    )
}

export default Home
