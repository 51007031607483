import { LockOpenIcon, DeviceMobileIcon } from '@heroicons/react/solid'
import LogoHorizonal from '../../Assets/logo_horizonal.svg'
import { Link} from  "react-router-dom";

function LoginForm() {
    return (<div className="h-screen flex">
        <div className=" lg:w-1/2 lg:flex hidden bg-gradient-to-tr from-yellow-400 to-amber-600 i justify-around items-center">
            <div>
                <img src={LogoHorizonal} className="h-20 drop-shadow-xl" alt="logo"/>
                <p className="text-white text-xl mt-4 clear-both">一站式智能会议管理系统</p>
                <Link to="/about" className="inline-block bg-white mt-4 px-4 py-2 rounded-md font-bold mb-2">了解更多</Link>
            </div>
        </div>
        <div className="flex lg:w-1/2 md:w-full justify-center items-center bg-white m-4 p-4">
            <form className="bg-white">
                <h1 className="text-gray-800 font-bold text-2xl mb-1">欢迎回来!</h1>
                <p className="text-sm font-normal text-gray-600 mb-7">Welcome Back</p>
                <div className="flex items-center border-2 py-2 px-3 rounded-lg mb-4">
                    <DeviceMobileIcon className="h-5 w-5 text-gray-400" />
                    <input className="pl-2 outline-none border-none" type="text"
                    autoComplete="tel"
                    name="mobile-number" id="mobile-number" placeholder="请输入手机号码" />
                </div>
                <div className="flex items-center border-2 py-2 px-3 rounded-md">
                    <LockOpenIcon className="h-5 w-5 text-gray-400" />
                    <input className="pl-2 outline-none border-none" type="text" name="sms-code" id="sms-code" placeholder="请输入验证码" />
                    <button className="ml-1 bg-gray-100 rounded-md text-gray-500 px-4 py-2">发送验证码</button>
                </div>
                <button type="submit" className="block w-full bg-yellow-500 mt-4 py-2 rounded-md text-gray-800 font-semibold mb-2">登录</button>
                <span className="text-sm ml-2 ">注册登录即表示同意 <a href="/" className='text-yellow-500 hover:text-yellow-400'>智游会务隐私声明</a></span>
            </form>
        </div>
    </div>);
}

export default LoginForm;
