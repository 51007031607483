import { CursorClickIcon } from "@heroicons/react/outline"
import Image3 from "../../Assets/Images/case/2cd0c3635253416fb5d07a99f8240ab7.jpg"
import Image2 from "../../Assets/Images/case/20200728021706_27141.jpg"
import Image1 from "../../Assets/Images/case/23160538u7yj.jpg"
import Image4 from "../../Assets/Images/case/1013135635.png"

const cases = {
    images: [
        {
            src: Image1,
            alt: "第12届国际基础设施骰子与建设高峰会",
        },
        {
            src: Image2,
            alt: "第30届中国食品博览会",
        },
        {
            src: Image3,
            alt: "中国国际智能产业博览会",
        },
        {
            src: Image4,
            alt: "世界视频（深圳）博览会",
        },
    ],
}


function CaseCard() {
    return (
        <div className="bg-white">
            <div className="pt-6">
                <div className="container flex flex-col items-center px-5 py-8 mx-auto max-w-7xl sm:px-6 lg:px-8">
                    <h1 className="text-3xl font-semilight tracking-tight text-gray-900">精选案例</h1>
                    <p className="py-6 text-lg font-light">定制化的解决方案，满足您的实际业务需求</p>
                </div>
                {/* Image gallery */}
                <div className="mb-16 max-w-2xl mx-auto sm:px-6 lg:max-w-7xl lg:px-8 grid lg:grid-cols-3 lg:gap-x-8 gap-y-8"
                    data-aos="fade-up" data-aos-offset="100" data-aos-delay="50" data-aos-duration="1200">
                    <div className="aspect-w-3 aspect-h-4 rounded-lg overflow-hidden group" >
                        <a href="/" >
                            <img
                                src={cases.images[0].src}
                                alt={cases.images[0].alt}
                                className="w-full h-full object-center object-cover"
                            />
                            <div
                                className="group-hover:opacity-100 bg-yellow-500/50 transition duration-300 absolute bottom-0 top-0 right-0 left-0 opacity-0 flex justify-center items-center flex-col">
                                <CursorClickIcon className="w-14 h-14 group-hover:text-gray-900/90 text-opacity-0  text-center" />
                                <h2 className="text-opacity-0 group-hover:text-gray-900/90 transition duration-300 text-center text-2xl">案例详情</h2>
                            </div>
                        </a>
                    </div>
                    <div className="grid grid-cols-1 gap-y-8 ">

                        <div className="aspect-w-3 aspect-h-2 rounded-lg overflow-hidden group">
                            <a href="/" >
                                <img
                                    src={cases.images[1].src}
                                    alt={cases.images[1].alt}
                                    className="w-full h-full object-center object-cover"
                                />
                                <div
                                    className="group-hover:opacity-100 bg-yellow-500/50 transition duration-300 absolute bottom-0 top-0 right-0 left-0 opacity-0 flex justify-center items-center">
                                    <CursorClickIcon className="w-14 h-14 group-hover:text-gray-900/90 text-opacity-0  text-center" />
                                    <h2 className="text-opacity-0 group-hover:text-gray-900/90 transition duration-300 text-center text-2xl">案例详情</h2>
                                </div>
                            </a>
                        </div>
                        <div className="aspect-w-3 aspect-h-2 rounded-lg overflow-hidden group">
                            <a href="/" >
                                <img
                                    src={cases.images[2].src}
                                    alt={cases.images[2].alt}
                                    className="w-full h-full object-center object-cover"
                                />
                                <div
                                    className="group-hover:opacity-100 bg-yellow-500/50 transition duration-300 absolute bottom-0 top-0 right-0 left-0 opacity-0 flex justify-center items-center">
                                    <CursorClickIcon className="w-14 h-14 group-hover:text-gray-900/90 text-opacity-0  text-center" />
                                    <h2 className="text-opacity-0 group-hover:text-gray-900/90 transition duration-300 text-center text-2xl">案例详情</h2>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="aspect-w-4 aspect-h-5 sm:rounded-lg sm:overflow-hidden lg:aspect-w-3 lg:aspect-h-4 group">
                        <a href="/" >
                            <img
                                src={cases.images[3].src}
                                alt={cases.images[3].alt}
                                className="w-full h-full object-center object-cover"
                            />
                            <div className="group-hover:opacity-100 bg-yellow-500/50 transition duration-300 absolute bottom-0 top-0 right-0 left-0 opacity-0 flex flex-col justify-center items-center">
                                <CursorClickIcon className="w-14 h-14 group-hover:text-gray-900/90 text-opacity-0  text-center" />
                                <h2 className="text-opacity-0 group-hover:text-gray-900/90 transition duration-300 text-center text-3xl">案例详情</h2>
                            </div>
                        </a>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default CaseCard;
