import React from 'react';

function ProductContainerR(props) {
    return (
        <>
            <div className="mx-4 py-4">
                <figure className="image">
                    <img className="md:w-[32rem] md:h-96 sm:w-60 sm:h-auto" src={require(`../../Assets/${props.image}`)} alt={props.title} />
                </figure>
            </div>
            <div className="mx-4 py-4">
                <h1 className='text-4xl block'>{props.title}</h1>
                <p className='text-lg whitespace-pre-line mt-4'>{props.content}</p>
            </div>

        </>
    );
}

export default ProductContainerR;
