function ApplymentForm() {

    return <div className="px-4 py-5 bg-white sm:p-6">
        <div className="grid grid-cols-6 gap-6">
            <div className="col-span-6 ">
                <label htmlFor="full-name" className="block text-sm font-medium text-gray-700">
                    姓名
                </label>
                <input
                    type="text"
                    name="full-name"
                    id="full-name"
                    autoComplete="name"
                    placeholder="请输入联系人姓名"
                    className="mt-1 focus:ring-yellow-500 focus:border-yellow-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
            </div>
            <div className="col-span-6 ">
                <label htmlFor="mobile-number" className="block text-sm font-medium text-gray-700">
                    手机号
                </label>
                <input
                    type="text"
                    name="mobile-number"
                    id="mobile-number"
                    autoComplete="tel"
                    placeholder="请输入手机号码"
                    className="mt-1 focus:ring-yellow-500 focus:border-yellow-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
            </div>
            <div className="col-span-6 ">
                <label htmlFor="company-name" className="block text-sm font-medium text-gray-700">
                    公司/组织机构全称
                </label>
                <input
                    type="text"
                    name="company-name"
                    id="company-name"
                    autoComplete="organization"
                    placeholder="请输入公司或组织机构全称"
                    className="mt-1 focus:ring-yellow-500 focus:border-yellow-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
            </div>
            <div className="col-span-6">
                <label htmlFor="conference-date" className="block text-sm font-medium text-gray-700">
                    活动/会议日期
                </label>
                <input
                    type="date"
                    name="conference-date"
                    id="conference-date"
                    placeholder="请选择活动/会议日期"
                    className="mt-1 focus:ring-yellow-500 focus:border-yellow-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
            </div>
            <div className="col-span-6 ">
                <label htmlFor="attendance" className="block text-sm font-medium text-gray-700">
                    活动/会议人数
                </label>
                <input
                    type="text"
                    name="attendance"
                    id="attendance"
                    placeholder="请输入活动/会议人数"
                    className="mt-1 focus:ring-yellow-500 focus:border-yellow-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
            </div>

            <div className="col-span-6 ">
                <label htmlFor="remark" className="block text-sm font-medium text-gray-700">
                    其他需求
                </label>
                <input
                    type="text"
                    name="remark"
                    id="remark"
                    autoComplete=""
                    placeholder="请输入您的其他需求"
                    className="mt-1 focus:ring-yellow-500 focus:border-yellow-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
            </div>
        </div>
    </div>;
}

export default ApplymentForm;
