import { useState, useEffect } from "react";

//router v6+ changed Switch to Routes
import { Routes, Route, useLocation } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';
import "./App.css";
import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";
import ApplyModal from './Components/Modals/ApplyModal';
import Home from "./Pages";

import About from "./Pages/about";
import Case from "./Pages/case";
import Events from "./Pages/events";
import Product from "./Pages/product";
import Login from "./Pages/login";

function App() {
  const [isOpen, setIsOpen] = useState(false);

  // const toggle = () => {
  //   setIsOpen(!isOpen);
  // };

  useEffect(() => {
    AOS.init({
      disable: 'mobile'
    }, []);

    // const hideMenu = () => {
    //   if (window.innerWidth > 768 && isOpen) {
    //     setIsOpen(false);
    //     console.log("I resized");
    //   }
    // };

    // window.addEventListener("resize", hideMenu);
    // return () => {
    //   window.removeEventListener("resize", hideMenu);
    // };
  });
  const excludedRoutes = ['/login', '/sign-up']
  const location = useLocation().pathname;

  return (
    <>
      {!excludedRoutes.includes(location) && <Navbar setIsOpen={setIsOpen} />}
      <Routes>
        <Route index element={<Home setIsOpen={setIsOpen}/>} />
        <Route path="case" element={<Case />} />
        <Route path="about" element={<About />} />
        <Route path="events" element={<Events />} />
        <Route path="product/:id" element={<Product />} />
        <Route path="login" element={<Login />} />
      </Routes>
      {!excludedRoutes.includes(location) && <Footer setIsOpen={setIsOpen} />}
      <ApplyModal setIsOpen={setIsOpen} isOpen={isOpen} />
    </>
  );
}

export default App;
