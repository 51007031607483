import React from 'react'
import EventList from '../Components/Event'
import EventHero from '../Components/Event/EventHero'
const Events = () => {
    return (
        <>
            <EventHero />
            <EventList />
        </>
    )
}

export default Events
