import { InboxInIcon, ReceiptRefundIcon, LightningBoltIcon, ViewBoardsIcon, ShieldCheckIcon, CubeTransparentIcon } from '@heroicons/react/outline'

const features = [
    {
        name: '安全稳定高效',
        description:
            '数据存储云服务器，自动备份有保障，轻松应对会议现场同时多人并发，保障会议顺利进行。',
        icon: ShieldCheckIcon,
        delay: 50
    },
    {
        name: '多端互通',
        description:
            'PC配置+大屏幕+小程序+H5，打破障碍多端互通，更有利于客户传播，提高参会体验。',
        icon: CubeTransparentIcon,
        delay: 550
    },
    {
        name: '全流程服务',
        description:
            'PC配置+大屏幕+小程序+H5，打破障碍多端互通，更有利于客户传播，提高参会体验。',
        icon: ViewBoardsIcon,
        delay: 1050
    },
    {
        name: '软硬件结合',
        description:
            '多种会议现场硬件辅助能力，包括人脸识别签到、扫码盒子签到等。',
        icon: InboxInIcon,
        delay: 1550
    },
    {
        name: '性价比高',
        description:
            '有效期内无限使用，多个版本灵活购买。',
        icon: ReceiptRefundIcon,
        delay: 2050
    },
    {
        name: '快速响应',
        description:
            '快速响应客户需求，积极优化改进；一对一专属客服，7*24h无忧服务。',
        icon: LightningBoltIcon,
        delay: 2550
    },
]
function AboutIntro() {
    return (
        <>
            <section>
                <div className="container flex flex-col items-center px-5 py-8 mx-auto max-w-7xl sm:px-6 lg:px-8">
                    <div className="flex flex-col w-full max-w-5xl mx-auto prose text-left">
                        <div className="w-full mx-auto">
                            <h1 className="text-center text-4xl lg:text-5xl pt-10">关于智游会务</h1>
                            <div data-aos="fade-up" data-aos-offset="100" data-aos-delay="50" data-aos-duration="1200">
                                <p className="pt-10 pb-5"> 上海游起网络科技有限公司成立于2015年，位于上海新天地。公司一直致力于互联网领域研发效率与品质的提升工作，
                                    目前广泛参与移动互联网、社交、商务，会务、旅行等业务体系的研发支撑工作。未来将进一步在效率提升领域、品质提升领域加大投入，不断提升价值，
                                    为更多的用户提供高品质服务。</p>
                                <p className="py-5"> 智游会务作为上海游起网络科技有限公司旗下产品，是一款深受主办方信赖和喜爱的活动举办平台，
                                    集活动发布、推广报名、签到验票、现场互动、线上展会、数据统计分析等功能于一体，
                                    提供一站式综合活动管理服务。</p>
                                <p className="py-5"> 目前，智游会务已支撑多种会议活动场景，如年会/员工大会、论坛峰会、发布会、培训/沙龙、政府会议、展会、合作伙伴大会、经销商大会等；
                                    同时，鲸会务已帮助超过3000+主办方解决会议问题，覆盖IT/互联网、商务零售、医疗健康、金融业、教育业、政府等多个领域，并深受用户好评。</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="w-full bg-center bg-cover bg-fixed h-80 bg-about-purpose">
                    <div className="flex items-center justify-center w-full h-full bg-yellow-400 bg-opacity-90">
                        <div className="text-center" >
                            <h1 className="text-5xl font-medium text-gray-900 uppercase lg:text-5xl ">公司愿景</h1>
                            <p className="pt-10 leading-relaxed text-4xl font-semibold text-gray-900 "
                                data-aos="fade-up" data-aos-offset="200" data-aos-delay="50" data-aos-duration="1000">“致力于为企业会议提供数字化的一站式解决方案”</p>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="pt-12 pb-24 bg-white">
                    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                        <div className="lg:text-center">
                            <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl" data-aos="fade-up" data-aos-offset="100" data-aos-delay="50" >
                                我们的优势
                            </p>
                        </div>

                        <div className="mt-16">
                            <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-3 md:gap-x-16 md:gap-y-12">
                                {features.map((feature) => (
                                    <div key={feature.name} className="relative" data-aos="fade-up" data-aos-offset="0" data-aos-delay={feature.delay}>
                                        <dt>
                                            <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-yellow-500 text-white">
                                                <feature.icon className="h-6 w-6" aria-hidden="true" />
                                            </div>
                                            <p className="ml-16 text-lg leading-6 font-medium text-gray-900">{feature.name}</p>
                                        </dt>
                                        <dd className="mt-2 ml-16 text-base text-gray-500">{feature.description}</dd>
                                    </div>
                                ))}
                            </dl>
                        </div>
                    </div>
                </div></section>
        </>
    );
}

export default AboutIntro;
