import React from 'react'
import CaseCard from '../Components/CaseCard'
import CaseGridList from '../Components/CaseGridList'
import CaseHero from '../Components/CaseHero'
import CaseStatistics from '../Components/CaseStatistics'
import DivideBanner from '../Components/DivideBanner'

const Case = () => {
  return (
    <>
    <CaseHero/>
    <CaseCard/>
    <CaseStatistics/>
    <CaseGridList/>
    <DivideBanner />
    </>
  )
}

export default Case
