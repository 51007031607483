import { OfficeBuildingIcon, UsersIcon, LightBulbIcon } from "@heroicons/react/outline"

function CaseStatistics() {
    return (
        <section className="bg-center bg-cover bg-fixed bg-case-statistics">
            <div className="w-full bg-yellow-400/90 ">
                <div className="container px-5 py-24 mx-auto ">
                    <div className="flex flex-col text-center w-full mb-20">
                        <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">助力众多企业实现数字化会议管理</h1>
                    </div>
                    <div className="flex flex-wrap flex-row divide-x divide-gray-800/50 -m-4 text-center drop-shadow-sm"
                    data-aos="fade-up" data-aos-offset="0" data-aos-delay="50" data-aos-duration="1000">
                        <div className="p-4 md:w-1/3 sm:w-1/2 w-full">
                            <div className="  px-4 py-6 ">
                                <OfficeBuildingIcon className="text-gray-900 w-12 h-12 mb-3 inline-block" />
                                <h2 className="title-font font-medium text-3xl text-gray-900">3000+</h2>
                                <p className="leading-relaxed">主办方</p>
                            </div>
                        </div>
                        <div className="p-4 md:w-1/3 sm:w-1/2 w-full">
                            <div className=" px-4 py-6 ">
                                <LightBulbIcon className="text-gray-900 w-12 h-12 mb-3 inline-block" />
                                <h2 className="title-font font-medium text-3xl text-gray-900">5000+</h2>
                                <p className="leading-relaxed">活动</p>
                            </div>
                        </div>
                        <div className="p-4 md:w-1/3 sm:w-1/2 w-full">
                            <div className=" px-4 py-6 ">
                                <UsersIcon className="text-gray-900 w-12 h-12 mb-3 inline-block" />
                                <h2 className="title-font font-medium text-3xl text-gray-900">400000+</h2>
                                <p className="leading-relaxed">参会者</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default CaseStatistics;
