import Image1 from "../../Assets/Images/1.jpg";
import Image2 from "../../Assets/Images/2.jpg";
import Image3 from "../../Assets/Images/3.jpg";
import Image4 from "../../Assets/Images/4.jpg";
import Image5 from "../../Assets/Images/5.jpg";
import Image6 from "../../Assets/Images/6.jpg";
import Image7 from "../../Assets/Images/7.jpg";
import Image8 from "../../Assets/Images/8.jpg";
import Image9 from "../../Assets/Images/9.jpg";
import Image10 from "../../Assets/Images/10.jpg";
import Image11 from "../../Assets/Images/11.jpg";
import Image12 from "../../Assets/Images/12.jpg";

const dataevents = [
    {
        id: 1,
        name: '2022中国展览经济发展关键词：专业性、数字化、绿色低碳',
        href: '#',
        content: '在第十七届中国会展经济国际合作论坛上对2021年国内外会展行业发展进行了回顾，并对行业2022年的发展趋势进行预测……',
        imageSrc: Image1,
        date: '2022-01-21'
    },
    {
        id: 2,
        name: '2021年度会议会展行业回望，这一趋势值得关注！',
        href: '#',
        content: '因为众所周知的原因，整个会议会展行业都在震荡中发展，而防控政策也客观上加速了行业的数字化升级，双线会议会展甚至线上会议会展成为大势所趋。在此过程中，一站',
        imageSrc: Image2,
        date: '2021-12-27'
    },
    {
        id: 3,
        name: '​会议活动“搞气氛”难？用这4招轻松搞定！',
        href: '#',
        content: '会议管理系统丰富的现场互动功能，可以为主办方一站搞定互动环节，带来精彩纷呈、高潮迭起的互动体验。数十种大屏互动功能，界面美观大气，操作简单易上手，',
        imageSrc: Image3,
        date: '2022-01-10'
    },
    {
        id: 4,
        name: '让企业效率提高的3个小改变',
        href: '#',
        content: '为何我们总说要提高效率和节约会议成本呢？原因非常简单，一是开得太多，二是开得太差；3个开会小改变可以帮助大家提升会议工作效率。',
        imageSrc: Image4,
        date: '2021-12-17'
    },
    {
        id: 5,
        name: '智游会务如何帮助主办方高效管理大型会议现场',
        href: '#',
        content: '一个能够贯穿会议活动全流程的智能会议管理系统，将为主办方的每场会议活动保驾护航，提升活动效率，保证活动效果。',
        imageSrc: Image5,
        date: '2021-12-17'
    },
    {
        id: 6,
        name: '江湖救急，如何做好年会主持？',
        href: '#',
        content: '如何做好年会主持工作？',
        imageSrc: Image6,
        date: '2021-12-17'
    },
    {
        id: 7,
        name: '职场秘籍：年会流程指导方案',
        href: '#',
        content: '年会倒计时已不足2个月，鲸会务给大家整理好了年会流程方案，听说搭配鲸会务年会套餐更优秀哦~',
        imageSrc: Image7,
        date: '2021-11-30'
    },
    {
        id: 8,
        name: '老板，这是我想要的年会奖品',
        href: '#',
        content: '智游会务在总结了各类年会奖品榜单后，给大家按照价格，列了一份奖品清单。',
        imageSrc: Image8,
        date: '2021-11-30'
    },
    {
        id: 9,
        name: '市场人必备的5种工具，轻松搞定活动策划',
        href: '#',
        content: '对于活动策划来说，创意和数据缺一不可。 鲸会务为大家推荐几款活动策划常用的工具，以此来协助制作出质量高的活动策划。',
        imageSrc: Image9,
        date: '2021-11-05'
    },
    {
        id: 10,
        name: '三个关键要素助力展会病毒营销',
        href: '#',
        content: '展会行业作为一个以人为中心的产业，在全民新媒体的时代，原有的营销方式显然不能触动观众，满足主办方想要达到的传播效果。如何造势和借势，打造热门话题，营造行',
        imageSrc: Image10,
        date: '2021-10-26'
    },
    {
        id: 11,
        name: '未来会展行业的7个关键词',
        href: '#',
        content: '整体来看未来会展行业一定会迎来一轮新的数字化浪潮的洗礼，除了掌握上述的7个关键词，如何利用好数字化工具也很重要。',
        imageSrc: Image11,
        date: '2021-10-17'
    },
    {
        id: 12,
        name: '展会后如何做好客户跟进销售',
        href: '#',
        content: '如何做好会后客户维护是一个值得主办方深思的问题，鲸会务结合行业经验给大家推荐几点思路……',
        imageSrc: Image12,
        date: '2021-09-18'
    },

]

const tags = [
    '在线会议', '智能会议系统', '会议直播', '远程会议软件', '会务服务', '微站', '小程序', '会议策划方案', '会议活动策划',
    '会议会展', '会议管理', '会议服务', '云展会', '直播', '会议营销', '线上展会', '营销自动化', '电子签到系统',
    '活动管理', '会议方案', '会议营销', '会议签到系统', '会议签到', '会议小游戏', '线上活动', '会议互动游戏',
    '年会抽奖', '二维码签到', '报名注册系统', '会议纪要', '学术会议', '活动策划', '发布会', '调查问卷', '会议软件',
    '数据分析', '会议管理系统', '会议邀请', '会议签到', '3D签到墙', '商务会议', 'H5', '活动邀约', '智能会议',
    '分会场管理', '会议服务公司', '年会活动策划', '创意年会', '年会签到', '微信墙互动', '会议系统公司', '展览数字化', '互动游戏',
    '会议通知', '峰会', '活动签到', '线上会议平台', '国际会议', '无纸化会议', '票务系统', '学术会议', '智慧场馆',
]
function EventList() {
    return (
        <div className="container mx-auto my-12">
            <div className="flex">
                {/*列表*/}
                <div className="md:w-3/4 grid grid-cols-1 gap-x-6 mr-10 border-t border-gray-100">
                    {dataevents.map((item) => (
                        <div className='grid grid-cols-6 p-4 group hover:bg-gray-50 gap-x-8' key={item.id}>
                            <a href={item.href} className="col-span-2">
                                <div className="w-full aspect-w-1 aspect-h-1 bg-gray-200 rounded-lg overflow-hidden xl:aspect-w-4 xl:aspect-h-3">
                                    <img
                                        src={item.imageSrc}
                                        alt={item.name}
                                        className="w-full h-full object-center object-cover"
                                    />
                                </div>

                            </a>
                            <div className="col-span-4 px-4">
                                <a href={item.href} className="col-span-1 ">
                                    <h3 className="block mt-4 text-2xl font-bold group-hover:text-yellow-500 text-gray-800">{item.name}</h3>
                                </a>
                                <p className="block mt-6 text-md  text-gray-600">{item.content}</p>
                                <i className='inline-block mt-6 not-italic text-gray-500'>{item.date}</i>
                            </div>
                        </div>
                    ))}
                </div>

                {/*标签*/}
                <div className="md:w-1/4 md:block hidden">
                    <div className="pt-4 border border-gray-100">
                        <h3 className="block mx-4 text-2xl font-bold  text-gray-800 border-b pb-4">热门标签</h3>
                        <div className="mt-6">
                            {tags.map((item, index) => (
                                <a href='/events' key={index} className='p-2 mb-4 text-sm lg:text-medium  mx-4 inline-flex bg-gray-50 hover:bg-gray-100'>{item}</a>
                            ))}
                        </div>
                    </div>
                    <div className="mt-6 pt-4 border border-gray-100">
                        <h3 className="block mx-4 text-2xl font-bold  text-gray-800 border-b border-gray-100 pb-4">关注智游会务</h3>
                        <div className="mt-6 flex-col items-center pb-6">
                            <img className="w-48 h-48 mx-auto" alt="扫码关注公众号" src="/images/qrcode.jpg" />
                            <h3 className='text-center'>关注智游会务</h3>
                            <h3 className='text-center'>获取更多产品信息</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>);
}

export default EventList;
