import { Fragment} from 'react'
import { Link } from "react-router-dom";
import logo from "../../Assets/logo_words.svg";
import { Popover, Transition } from '@headlessui/react'
import {
  MenuIcon,
  ChartSquareBarIcon,
  CursorClickIcon,
  ClipboardIcon,
  SpeakerphoneIcon,
  IdentificationIcon,
  LightBulbIcon,
  QrcodeIcon,
  XIcon,
} from '@heroicons/react/outline'
import { ChevronDownIcon } from '@heroicons/react/solid'



function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const products = [
  {
    name: '会展营销',
    description: '自助式的活动营销与管理系统，管理与营销运营的一站式平台',
    href: '/product/marketing',
    icon: SpeakerphoneIcon,
  },
  {
    name: '会议签到',
    description: '自助使用的电子签到系统，1秒签到，5步完成',
    href: '/product/checkin',
    icon: ClipboardIcon,
  },
  {
    name: '接待管理',
    description: "智能接待系统，1个小程序，4方协同轻松管理",
    href: '/product/management',
    icon: IdentificationIcon
  },
  {
    name: '精彩互动',
    description: "会场互动软件与服务：互动抽奖、问卷、签约等",
    href: '/product/interact',
    icon: CursorClickIcon,
  },
  {
    name: '数据分析',
    description: '一站式、多维度的会展数据监测与分析平台',
    href: '/product/da',
    icon: ChartSquareBarIcon,
  },

]


const callsToAction = [
  { name: '产品体验', href: '#', icon: QrcodeIcon },
  { name: '最新活动', href: '/events', icon: LightBulbIcon },
]


const resources = [
  {
    name: '返回首页',
    href: '/',

  },
  {
    name: '用户案例',
    href: '/case',

  },
  {
    name: '最新活动',
    href: '/events',
  },
  {
    name: '关于我们',
    href: '/about',
  },
]


const Navbar = ({setIsOpen}) => {
  function openModal() {
    setIsOpen(true)
  }
  
  return (
    <Popover className="sticky z-20 top-0 inset-0 bg-white shadow-sm  text-black font-sans " role="navigation">
      <div className="max-w-7xl mx-auto p-4 flex justify-between items-center md:justify-start md:space-x-10">
        <div className="flex justify-start lg:w-0 lg:flex-1">
          <Link to="/" className="pl-6">
            <img src={logo} className="h-10" alt="logo" />
          </Link>
        </div>
        <div className="px-4 cursor-pointer md:hidden">
          {/* 手机端导航菜单切换按钮 */}
          <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center  hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-yellow-500">
            <span className="sr-only">Open menu</span>
            <MenuIcon className="h-6 w-6" aria-hidden="true" />
          </Popover.Button>
        </div>
        <Popover.Group as="nav" className="hidden md:flex space-x-10">
          <Link className="p-4" to="/">
            首页
          </Link>
          <Popover className="p-4">
            {({ open }) => (
              <>
                <Popover.Button
                  className={classNames(
                    open ? 'text-gray-600' : 'text-black',
                    'group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900  focus:outline-none focus:ring-2 focus:ring-offset-4 focus:ring-yellow-500'
                  )}
                >
                  <span>产品服务</span>
                  <ChevronDownIcon
                    className={classNames(
                      open ? 'text-gray-600' : 'text-black',
                      'ml-2 h-5 w-5 group-hover:text-gray-600'
                    )}
                    aria-hidden="true"
                  />
                </Popover.Button>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <Popover.Panel className="absolute z-10 -ml-4 mt-3 transform px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2">
                    <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                      <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                        {products.map((item) => (
                          <a
                            key={item.name}
                            href={item.href}
                            className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                          >
                            <item.icon className="flex-shrink-0 h-6 w-6 text-yellow-500" aria-hidden="true" />
                            <div className="ml-4">
                              <p className="text-base font-medium text-gray-900">{item.name}</p>
                              <p className="mt-1 text-sm text-gray-500">{item.description}</p>
                            </div>
                          </a>
                        ))}
                      </div>
                      <div className="px-5 py-5 bg-gray-50 space-y-6 sm:flex sm:space-y-0 sm:space-x-10 sm:px-8">
                        {callsToAction.map((item) => (
                          <div key={item.name} className="flow-root">
                            <a
                              href={item.href}
                              className="-m-3 p-3 flex items-center rounded-md text-base font-medium text-gray-900 hover:bg-gray-100"
                            >
                              <item.icon className="flex-shrink-0 h-6 w-6 text-gray-400" aria-hidden="true" />
                              <span className="ml-3">{item.name}</span>
                            </a>
                          </div>
                        ))}
                      </div>
                    </div>
                  </Popover.Panel>
                </Transition>
              </>
            )}
          </Popover>
          <Link className="p-4" to="/case">
            用户案例
          </Link>
          <Link className="p-4" to="/about">
            关于我们
          </Link>
        </Popover.Group>
        <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
          <Link
            to="/login" className="whitespace-nowrap inline-flex items-center justify-center px-8 py-2 border border-transparent rounded-md shadow-sm text-base font-bold bg-yellow-500  hover:bg-yellow-300 transition duration-300 ease-in-out"
          >
            登{' '}录
          </Link>
          <button type="button"
            onClick={openModal}
            className="ml-8 whitespace-nowrap text-base font-medium text-gray-500 hover:text-gray-900">
            申请试用
          </button>
        </div>
      </div>

      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel focus className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">
          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
            <div className="pt-5 pb-6 px-5">
              <div className="flex items-center justify-between">
                <div>
                  <img
                    className="h-8 w-auto"
                    src="/favicon.ico"
                    alt="Logo"
                  />
                </div>
                <div className="-mr-2">
                  <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-yellow-500">
                    <span className="sr-only">关闭菜单</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="mt-6">
                <nav className="grid gap-y-8">
                  {products.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50"
                    >
                      <item.icon className="flex-shrink-0 h-6 w-6 text-yellow-500" aria-hidden="true" />
                      <span className="ml-3 text-base font-medium text-gray-900">{item.name}</span>
                    </a>
                  ))}
                </nav>
              </div>
            </div>
            <div className="py-6 px-5 space-y-6">
              <div className="grid grid-cols-2 gap-y-4 gap-x-8">
                {resources.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className="text-base font-medium text-gray-900 hover:text-gray-700"
                  >
                    {item.name}
                  </a>
                ))}
              </div>
              <div>
                <button
                  type="button"
                  onClick={openModal}
                  className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium  bg-yellow-500 hover:bg-yellow-300"
                >
                  申请试用
                </button>
                <p className="mt-6 text-center text-base font-medium text-gray-500">
                  已经注册?{' '}
                  <Link to="/login" className="text-yellow-500 hover:text-yellow-600">
                    登录
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
      

    </Popover>
  );
};

export default Navbar;
