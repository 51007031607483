import React from 'react';
import { Link } from 'react-router-dom';

function NoMatch() {
    return <div className="h-screen flex flex-col justify-center items-center bg-yellow-400">
        <h1 className="text-5xl uppercase font-black">无效的地址</h1>

        <p className="mt-4 text-2xl">
            <Link to="/" className="underline">返回首页</Link>
        </p>

    </div>;
}

export default NoMatch;
