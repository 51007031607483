import React from 'react';
import products from "../../Data/product_data.json"
import ProductContainerL from './ProductContainerL';
import ProductContainerR from './ProductContainerR';
function classNames(...classes) {
  return classes.join(' ');
}
function ProductCategories(props) {
  let product = [];
  switch (props.category) {
    case "marketing":
      product = products.marketing;
      break;
    case "checkin":
      product = products.checkin;
      break;
    case "management":
      product = products.management;
      break;
    case "interact":
      product = products.interactive;
      break;
    case "da":
      product = products.da;
      break;
    default:
      product = [];
      break;
  }

  return (
    <>
      {product.map((item) => (
        <div
          key={item.id}
          className={
            classNames(
              'w-full h-144 flex',
              item.bg
            )
          }>
          <div className={
            classNames(
              'container mx-auto flex items-center',
              (item.id === 0) ? 'justify-between' : 'justify-evenly'
            )
          }
          data-aos={
            (item.id === 0)?'fade-up':(item.id%2===0)?'slide-left':'slide-right'
          }  
          data-aos-offset="200"
          data-aos-delay="50"
          data-aos-duration="1800"
          data-aos-once="true"
          >
            {
              (item.id % 2 === 0) ?
                <ProductContainerL title={item.title} content={item.content} image={item.image} /> :
                <ProductContainerR title={item.title} content={item.content} image={item.image} />
            }
          </div>
        </div>
      ))}
    </>
  );
}

export default ProductCategories;
