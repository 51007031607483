import React from 'react';
import NoMatch from '../Components/NoMatch';
import ProductCategories from '../Components/ProductCategories';
import {
  useParams
} from "react-router-dom";
import DivideBanner from '../Components/DivideBanner';

function Product() {
  const category = ["marketing", "checkin", "management", "interact", "da"];
  let { id } = useParams();
  if (!category.includes(id)) {
    return <NoMatch />;
  }
  return (
    <>
      <ProductCategories category={id} />
      <DivideBanner/>
    </>
  );
}

export default Product;
