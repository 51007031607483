import logo from "../../Assets/logo_literal.svg"
import footerDataService from "../../Data/footer_data_service.json"
import footerDataFeature from "../../Data/footer_data_features.json"
import footerDataAbout from "../../Data/footer_data_about.json"
import footerDataResources from "../../Data/footer_data_resources.json"


const Footer = ({ setIsOpen }) => {
    function openModal() {
        setIsOpen(true)
    }
    return (
        <>
            <footer className="footer-1 bg-gray-100 py-8 sm:py-12">
                <div className="container mx-auto px-4">
                    <div className="sm:flex sm:flex-wrap sm:-mx-4 md:py-4">
                        <div className="px-4 sm:w-1/2 md:w-1/4 xl:w-1/6">
                            <h5 className="text-xl font-bold mb-6">产品</h5>
                            <ul className="list-none footer-links">
                                {footerDataFeature.map((item) => (
                                    <li key={item.name} className="mb-2">
                                        <a href={item.href} className="hover:text-yellow-500 hover:drop-shadow-sm transition duration-300 ease-in-out">{item.name}</a>
                                    </li>
                                ))
                                }

                            </ul>
                        </div>
                        <div className="px-4 sm:w-1/2 md:w-1/4 xl:w-1/6 mt-8 sm:mt-0">
                            <h5 className="text-xl font-bold mb-6">解决方案</h5>
                            <ul className="list-none footer-links">

                                {footerDataResources.map((item) => (
                                    <li key={item.name} className="mb-2">
                                        <a href={item.href} className="hover:text-yellow-500 hover:drop-shadow-sm transition duration-300 ease-in-out">{item.name}</a>
                                    </li>
                                ))
                                }
                            </ul>
                        </div>
                        <div className="px-4 sm:w-1/2 md:w-1/4 xl:w-1/6 mt-8 md:mt-0">
                            <h5 className="text-xl font-bold mb-6">客户服务</h5>
                            <ul className="list-none footer-links">
                                {footerDataService.map((item) => (
                                    <li key={item.name} className="mb-2">
                                        <a href={item.href} className="hover:text-yellow-500 hover:drop-shadow-sm transition duration-300 ease-in-out">{item.name}</a>
                                    </li>
                                ))
                                }
                            </ul>
                        </div>
                        <div className="px-4 sm:w-1/2 md:w-1/4 xl:w-1/6 mt-8 md:mt-0">
                            <h5 className="text-xl font-bold mb-6">其他</h5>
                            <ul className="list-none footer-links">
                                {footerDataAbout.map((item) => (
                                    <li key={item.name} className="mb-2">
                                        <a href={item.href} className="hover:text-yellow-500 hover:drop-shadow-sm transition duration-300 ease-in-out ">{item.name}</a>
                                    </li>
                                ))
                                }
                            </ul>
                        </div>
                        <div className="px-4 mt-4 sm:w-1/3 xl:w-1/6 sm:mx-auto xl:mt-0 xl:ml-auto">
                            <h5 className="text-xl font-bold mb-6 sm:text-center xl:text-left">官方公众号</h5>
                            <div className="flex sm:justify-center xl:justify-start">
                                <img className="w-28 h-28" alt="扫码关注公众号" src="/images/qrcode.jpg" />
                            </div>
                        </div>
                    </div>

                    <div className="sm:flex sm:flex-wrap sm:-mx-4 mt-6 pt-6 sm:mt-12 sm:pt-12 border-t">
                        <div className="sm:w-full px-4 md:w-1/6">
                            <img src={logo} alt="智游会务" className="h-12" />
                        </div>
                        <div className="px-4 sm:w-1/2 md:w-1/4 mt-4 md:mt-0">
                            <h6 className="font-bold mb-2">公司地址</h6>
                            <address className="not-italic mb-4 text-sm">
                                上海市{' '}黄浦区<br />湖滨路222号企业广场一期18楼
                            </address>
                        </div>
                        <div className="px-4 sm:w-1/2 md:w-1/4 mt-4 md:mt-0">
                            <h6 className="font-bold mb-2">备案信息</h6>
                            <p className="mb-4 text-sm"><a target="_blank" rel="noreferrer nofollow" href="http://www.beian.miit.gov.cn/" className="text-base">网站备案号:沪ICP备15024863号-1 </a></p>
                        </div>
                        <div className="px-4 md:w-1/4 md:ml-auto mt-6 sm:mt-4 md:mt-0">
                            <button type="button" onClick={openModal} className="px-4 py-2 bg-yellow-500 hover:bg-yellow-400 rounded text-black transition duration-300 ease-in-out">申请试用</button>
                        </div>
                    </div>
                </div>
            </footer>
            <div className="flex justify-center items-center h-16 bg-black text-white text-sm">
                <p>Copyright &copy; 2015 - {new Date().getUTCFullYear()} 上海游起网络科技有限公司 版权所有</p>
            </div>
        </>

    )
}

export default Footer;