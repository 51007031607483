import { SpeakerphoneIcon, XIcon } from "@heroicons/react/outline"
import { Link } from "react-router-dom"
import BannerData from "../../Data/banner_data.json"

function Banner() {
    return (
        <div className="bg-yellow-500">
            <div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
                <div className="flex items-center justify-between flex-wrap">
                    <div className="w-0 flex-1 flex items-center">
                        <span className="flex p-2 rounded-lg bg-yellow-600">
                            <SpeakerphoneIcon className="h-6 w-6 text-white" aria-hidden="true" />
                        </span>
                        {BannerData.map((item) => (
                            <p key={item.name} className="ml-3 font-medium text-black truncate">
                                <span className="md:hidden">{item.name}</span>
                                <span className="hidden md:inline">{item.description}</span>
                            </p>
                        ))}
                    </div>
                    <div className="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
                        <Link
                            to="#"
                            className="flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-gray-800 bg-white hover:bg-gray-50"
                        >
                            了解更多
                        </Link>
                    </div>
                    <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-3">
                        <button
                            type="button"
                            className="-mr-1 flex p-2 rounded-md  focus:outline-none focus:ring-2 focus:ring-white sm:-mr-2"
                        >
                            <span className="sr-only">关闭</span>
                            <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Banner
