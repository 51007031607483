import CaseImage1 from "../../Assets/Images/case/case01_1.jpg";
import CaseImage2 from "../../Assets/Images/case/case-1.jpg";
import CaseImage3 from "../../Assets/Images/case/case-2.jpg";
import CaseImage4 from "../../Assets/Images/case/case-3.png";
import CaseImage5 from "../../Assets/Images/case/case-4.jpg";
import CaseImage6 from "../../Assets/Images/case/case-5.jpg";
import CaseImage7 from "../../Assets/Images/case/case01_3.jpg";
import CaseImage8 from "../../Assets/Images/case/case-7.png";
import CaseImage9 from "../../Assets/Images/case/case-8.jpg";
import CaseImage10 from "../../Assets/Images/case/case-9.png";
import CaseImage11 from "../../Assets/Images/case/case-10.png";
import CaseImage12 from "../../Assets/Images/case/case-11.png";

const products = [
    {
        id: 1,
        name: "2021年重庆油气论坛夏季峰会",
        href: "#",
        imageSrc: CaseImage1,
        imageAlt: "2021年重庆油气论坛夏季峰会",
        title: "$35",
        intro: "聚焦“十四五”创新发展，2021年重庆油气论坛夏季峰会成功举行，为本次峰会搭建微站，提供一站式",
    },
    {
        id: 2,
        name: "第九届中国营养与保健食品行业创新论坛",
        href: "#",
        imageSrc: CaseImage2,
        imageAlt: "第九届中国营养与保健食品行业创新论坛",
        title: "$35",
        intro: "作为专业的数字会议新基建服务商，已连续多次为 “创新汇” 论坛提供会议技术服务与支持，赢得主办方的高度认可。",
    },
    {
        id: 3,
        name: "第十六届中国（宁波）食品博览会",
        href: "#",
        imageSrc: CaseImage3,
        imageAlt: "第十六届中国（宁波）食品博览会",
        title: "$35",
        intro: "作为本届食博会数字会展服务商，提供云展会解决方案，重塑展示场景和逛展体验，实现线上线下相结合的方式，对展会...",
    },
    {
        id: 4,
        name: "2020垃圾分类·杭州高峰论坛",
        href: "#",
        imageSrc: CaseImage4,
        imageAlt: "2020垃圾分类·杭州高峰论坛",
        title: "$35",
        intro: "作为本届高峰论坛的数字会议技术服务商，帮助论坛实现了数字化办会模式，快速搭建活动专属微站...",
    },
    {
        id: 5,
        name: "第八届中国营养与保健食品行业创新论坛",
        href: "#",
        imageSrc: CaseImage5,
        imageAlt: "第八届中国营养与保健食品行业创新论坛",
        title: "$35",
        intro: "为本次论坛活动提供了一站式的数字会议解决方案，包括：电子邀请函、小程序分享、二维码签到、现场互动、会后再营...",
    },
    {
        id: 6,
        name: "菲尼克斯产品发布会",
        href: "#",
        imageSrc: CaseImage6,
        imageAlt: "菲尼克斯产品发布会",
        title: "$35",
        intro: "作为本次发布会的会议解决工具，帮助主办方直观展示大会主题，轻松实现会议推广及邀约，多种现场互动功能暖场，精...",
    },
    {
        id: 7,
        name: "首届教育培训行业博鳌峰会",
        href: "#",
        imageSrc: CaseImage7,
        imageAlt: "首届教育培训行业博鳌峰会",
        title: "$35",
        intro: "全国首届教育培训行业博鳌峰会暨第二十届八大名师论坛在海南博鳌亚洲论坛会议中心举办，为本次峰会搭建微站，提供...",
    },
    {
        id: 8,
        name: "邮电职业技术学院学术交流会议",
        href: "#",
        imageSrc: CaseImage8,
        imageAlt: "邮电职业技术学院学术交流会议",
        title: "$35",
        intro: "邮电职业技术学院的学术交流与会务管理工作提供数字化、互动化、高效便捷的管理平台。通过会前邀约、推广宣传",
    },
    {
        id: 9,
        name: "中建一局线上才艺展示晚会",
        href: "#",
        imageSrc: CaseImage9,
        imageAlt: "中建一局线上才艺展示晚会",
        title: "$35",
        intro: "在全球疫情仍在蔓延的情况下，面对广大企业所面临的“开会难”的问题，为企业量身定制了线上会议互动的解决方案。",
    },
    {
        id: 10,
        name: "黑龙江省第十二次妇女代表大会",
        href: "#",
        imageSrc: CaseImage10,
        imageAlt: "黑龙江省第十二次妇女代表大会",
        title: "$35",
        intro: "为本次大会提供了包括资料管理系统、日程管理、签到管理等一站式大会解决方案，成功助力此次政府性会议圆满落幕！",
    },
    {
        id: 11,
        name: "领地集团20周年庆",
        href: "#",
        imageSrc: CaseImage11,
        imageAlt: "领地集团20周年庆",
        title: "$35",
        intro: "领地20周年庆，健康生活更美好，公司20周年庆圆满举办。会上员工为公司留言送上美好祝福，同时通过抽奖环节将会议...",
    },
    {
        id: 12,
        name: "天津电装电机有限公司家庭日活动",
        href: "#",
        imageSrc: CaseImage12,
        imageAlt: "天津电装电机有限公司家庭日活动",
        title: "$35",
        intro: "家庭日现场汇聚公司员工及家属儿童，欢聚一堂开展活动，鲸会务基于家庭日活动内容，打造互动性强的活动体验，使...",
    },
    // More products...
]

function CaseGridList() {
    return (
        <div className="bg-white">
        <div className="max-w-2xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8">
            <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 text-center">更多案例</h2>

            <div className="mt-16 grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-3 xl:gap-x-6">
                {products.map((product) => (
                    <div key={product.id} className="group relative shadow-lg rounded-lg overflow-hidden" data-aos="fade-up" data-aos-duration="800">
                        <div className="w-full min-h-80 bg-gray-200 aspect-w-1 aspect-h-1 overflow-hidden group-hover:opacity-75 lg:h-80 lg:aspect-none">
                            <img
                                src={product.imageSrc}
                                alt={product.imageAlt}
                                className="w-full h-full object-center object-cover lg:w-full lg:h-full"
                            />
                        </div>
                        <div className="p-6 flex justify-between">
                            <div>
                                <h3 className="text-sm text-gray-700">
                                    <a href={product.href}>
                                        <span aria-hidden="true" className="absolute inset-0" />
                                        {product.name}
                                    </a>
                                </h3>
                                <p className="mt-1 text-sm text-gray-500">{product.intro}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    </div>
    );
}

export default CaseGridList;
