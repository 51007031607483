import React from 'react'

function DivideBanner() {
    return (
        <div className="relative bg-white">
            <p className="bg-yellow-500 h-10 flex items-center justify-center text-sm font-black text-gray-800 px-4 sm:px-6 lg:px-8">
                现在申请试用可获得100元代金券优惠！
            </p>
        </div>
    )
}

export default DivideBanner
