import { QrcodeIcon } from '@heroicons/react/outline'
const Hero = ({setIsOpen}) => {
    function openModal() {
        setIsOpen(true)
    }

    return (
        <>
            <div className="bg-indigo-800/[.50] lg:h-144 md:h-128 h-screen flex flex-col justify-center items-center w-full object-contain">
                <video className="fixed z-[-1] object-cover h-full w-full" src="/videos/Plexus_human_body_white_on_blue.mp4" autoPlay loop muted />
                <h1 className="text-white drop-shadow-[0_2px_2px_rgba(0,0,0,0.6)] lg:text-6xl md:text-6xl sm:text-5xl text-4xl font-black mb-14 mt-10">
                    智能时代数字化展会解决方案
                </h1>
                <button type="button" onClick={openModal} className='py-6 px-10 bg-yellow-500 rounded-full text-3xl hover:bg-yellow-300 transition duration-300 ease-in-out flex items-center animate-bounce'>立即预约
                    <QrcodeIcon className="h-6 w-6" />
                </button>
            </div>
        </>
    )
};

export default Hero;