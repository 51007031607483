import features from "../../Data/feature_data.json"
import feature_images from "../../Data/feature_data_images.json"
import {SunIcon} from "@heroicons/react/outline"

function Features() {
    
    return (
        <div className="bg-white">
            <div className="max-w-2xl mx-auto py-24 px-4 grid items-center grid-cols-1 gap-y-16 gap-x-8 sm:px-6 sm:py-32 lg:max-w-7xl lg:px-8 lg:grid-cols-2">
                <div>
                    <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">六大核心功能助力会议智能化管理</h2>
                    <p className="mt-4 text-gray-500">
                    <SunIcon className="h-6 w-6 text-yellow-500 inline-block" aria-hidden="true" /> 
                    一站式会议管理，打通企业微信和微信双平台，提供会前、会中、会后的全流程会议解决方案。                       
                    </p>
                    <dl className="mt-16 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 sm:gap-y-16 lg:gap-x-8">
                        {features.map((feature) => (
                            <div key={feature.name} className="border-t border-gray-200 pt-4">
                                <dt className="font-medium text-gray-900 ">{feature.name}</dt>
                                <dd className="mt-2 text-sm text-gray-500 whitespace-pre-line">{feature.description}</dd>
                            </div>
                        ))}
                    </dl>
                </div>
                <div className="grid grid-cols-2 grid-rows-2 gap-4 sm:gap-6 lg:gap-8">
                {feature_images.map((image) => (
                    <img key={image.alt}
                        src={require(`../../Assets/${image.src}`)}
                        alt={image.alt}
                        className="bg-gray-100 rounded-lg"
                    />
                ))}
                </div>
            </div>
        </div>
    )
}

export default Features
