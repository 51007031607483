import { AnnotationIcon, GlobeAltIcon, LightningBoltIcon, ScaleIcon } from '@heroicons/react/outline'

const features = [
    {
        name: '功能齐备',
        description:
            '集会前宣传，会中互动，会后统计等功能于一体，打造全流程会议管理服务。',
        icon: GlobeAltIcon,
    },
    {
        name: '多场景适用',
        description:
            '支撑多种会议场景，提供个性化解决方案，支持客户定制开发，高性价比。',
        icon: ScaleIcon,
    },
    {
        name: '经验丰富',
        description:
            '服务超3000家企业，覆盖IT/互联网、商务零售、医疗健康、金融业、教育业、政府等多个领域。',
        icon: LightningBoltIcon,
    },
    {
        name: '无忧售后',
        description:
            '一对一专属服务，快速响应客户需求，积极优化改进，7*24h无忧服务。',
        icon: AnnotationIcon,
    },
]

function FeatureSection() {
    return (
        <div className="py-12 bg-white">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="lg:text-center">
                    <h2 className="text-base text-yellow-500 font-semibold tracking-wide uppercase" data-aos="fade-down" data-aos-offset="200" data-aos-delay="50" >为什么选择智游？</h2>
                    <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl" data-aos="fade-up" data-aos-offset="200" data-aos-delay="50" >
                        我们的优势
                    </p>
                    <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto" data-aos="fade-up" data-aos-offset="200" data-aos-delay="50" >
                        助力众多企业实现数字化会议管理
                    </p>
                </div>

                <div className="mt-10" data-aos="fade-up" data-aos-offset="200" data-aos-delay="50" >
                    <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
                        {features.map((feature) => (
                            <div key={feature.name} className="relative">
                                <dt>
                                    <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-yellow-500 text-white">
                                        <feature.icon className="h-6 w-6" aria-hidden="true" />
                                    </div>
                                    <p className="ml-16 text-lg leading-6 font-medium text-gray-900">{feature.name}</p>
                                </dt>
                                <dd className="mt-2 ml-16 text-base text-gray-500">{feature.description}</dd>
                            </div>
                        ))}
                    </dl>
                </div>
            </div>
        </div>
    )
}

export default FeatureSection
