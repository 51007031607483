import React from 'react';
import LoginForm from '../Components/LoginForm';
//import FormModalExample from '../Components/FormModalExample';
function Login() {
    return (<div>
        <LoginForm />
    </div>);
}

export default Login;
