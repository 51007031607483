import React from 'react'

function CaseHero() {
    return (
        <div className="w-full bg-bottom bg-cover bg-fixed h-128 bg-hero-pattern-case">
            <div className="flex items-center justify-center w-full h-full bg-indigo-900 bg-opacity-50">
                <div className="text-center drop-shadow-sm" data-aos="fade-up" data-aos-offset="200" data-aos-delay="50" data-aos-duration="1200">
                    <h1 className="text-4xl font-semibold text-white lg:text-5xl italic ">助力客户实现数字化会议</h1>
                    <p className="pt-5 leading-relaxed text-2xl font-medium text-white ">智游会务正在帮助万家企业及组织迈入智慧数字会议新时代，助力提升办会品质与效率</p>
                </div>
            </div>
        </div>
    )
}

export default CaseHero
