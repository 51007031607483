import { useState } from 'react'
import { Tab } from '@headlessui/react'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Example() {
    let [categories] = useState({
        云展会:
        {
            id: 1,
            title: '云展会解决方案',
            date: '5h ago',
            commentCount: 5,
            shareCount: 2,
            image: "Images/15.jpg",
            content: "云展会解决方案，结合宣传微站、线上展览、票务管理、直播互动等核心功能，集展示、引流、互动为一体，帮助主办方提升运营效率、加速数据协同、加强服务体验和组织竞争力，实现展会的数字化转型。"
        },
        发布会:
        {
            id: 1,
            title: '发布会解决方案',
            date: 'Jan 7',
            commentCount: 29,
            shareCount: 16,
            image: "Images/16.jpg",
            content: "发布会解决方案，科技感十足，有体验，有颜值，有场面。作为企业每年都要筹划的重要会议，鲸会务旨在给所有参会嘉宾带来不一样的惊喜和与众不同的创意，激励销售代理目标实现。"
        },
        高峰论坛:
        {
            id: 1,
            title: '高峰论坛解决方案',
            date: '2d ago',
            commentCount: 9,
            shareCount: 5,
            image: "Images/17.jpg",
            content: "高峰论坛解决方案，以科技手段助力各级政府机构、行业协会和企业，传递价值，开放合作。"
        },
        政府大会:
        {
            id: 1,
            title: '政府大会解决方案',
            date: '2d ago',
            commentCount: 9,
            shareCount: 5,
            image: "Images/18.jpg",
            content: "政府大会解决方案，针对政府大会安全级别高，权限复杂，数据安全实时监测要求高等一系列特点，鲸会务在会议安全性、高效性、有序性方面有强大的优势，集智慧邀约、智慧通知、智慧接待、智慧签到、智慧监控等功能于一体，让政府会议管理更高效。"
        },
        年会庆典:
        {
            id: 1,
            title: '年会庆典解决方案',
            date: '2d ago',
            commentCount: 9,
            shareCount: 5,
            image: "Images/19.jpg",
            content: "年会庆典解决方案，包含数十款大屏互动功能，通过手机扫一扫、摇一摇即可完成互动，结合当下最火的直播模式，助力打造科技感十足的年会庆典！"
        },
        在线会议:
        {
            id: 1,
            title: '在线会议解决方案',
            date: '2d ago',
            commentCount: 9,
            shareCount: 5,
            image: "Images/20.jpg",
            content: "在线会议解决方案，较传统形式的会议相比，具备科技感强、办会成本低、摆脱地域限制、流程简洁等独特优势，是疫情常态化大环境之下的不二选择。"
        },
    })

    return (
        <div className="bg-yellow-500 lg:h-144 w-full object-contain">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16 ">
                <div className="lg:text-center">
                    <p className="mt-2 mb-10 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 drop-shadow sm:text-4xl">
                        覆盖多种会议场景解决方案
                    </p>

                </div>

                <Tab.Group>
                    <Tab.List className="flex p-1 space-x-1 bg-yellow-500/80 rounded-xl">
                        {Object.keys(categories).map((category) => (
                            <Tab
                                key={category}
                                className={({ selected }) =>
                                    classNames(
                                        'w-full py-2.5 text-sm leading-5 font-medium text-gray-800 rounded-lg',
                                        'focus:outline-none focus:ring-2 ring-offset-2 ring-offset-yellow-400 ring-white ring-opacity-60',
                                        selected
                                            ? 'bg-white shadow font-bold'
                                            : 'text-gray-500 hover:bg-white/[0.12] hover:text-white'
                                    )
                                }
                            >
                                {category}
                            </Tab>
                        ))}
                    </Tab.List>
                    <Tab.Panels className="mt-2">
                        {Object.values(categories).map((posts, idx) => (
                            <Tab.Panel
                                key={idx}
                                className={classNames(
                                    'bg-white rounded-xl p-3',
                                    'focus:outline-none focus:ring-2 ring-offset-2 ring-offset-yellow-400 ring-white ring-opacity-60'
                                )}
                            >
                                <div className="flex flex-wrap p-8 space-x-6 lg:space-x-8">
                                    <div className="w-full mb-2 lg:w-4/12">
                                        <img className="object-cover rounded-lg" src={require(`../../Assets/${posts.image}`)} alt={posts.title} />
                                    </div>
                                    <div
                                        key={posts.id}
                                        className="w-full lg:w-7/12 p-3"
                                    >
                                        <h1 className="text-lg font font-extrabold tracking-tight text-gray-900 sm:text-3xl">
                                            {posts.title}
                                        </h1>
                                        <div className="flex py-5 items-center">
                                            <div className="flex-grow border-t border-gray-200"></div>
                                        </div>
                                        <p className="text-md text-gray-500 divide-y divide-solid mb-10">{posts.content}</p>
                                        <a
                                            href="/"
                                            className={classNames(
                                                'rounded-md bg-yellow-400 py-2 px-4 relative',
                                                'focus:z-10 focus:outline-none focus:ring-2'
                                            )}
                                        >了解更多</a>
                                    </div>

                                </div>
                            </Tab.Panel>
                        ))}
                    </Tab.Panels>
                </Tab.Group>
            </div>
        </div>
    )
}
