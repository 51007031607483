import React from 'react'

function AboutHero() {
    return (
        <div className="w-full bg-center bg-cover bg-fixed h-[32rem] bg-hero-pattern-about">
            <div className="flex items-center justify-center w-full h-full bg-indigo-900 bg-opacity-40">
                <div className="text-center drop-shadow-md" data-aos="fade-up" data-aos-offset="200" data-aos-delay="50" data-aos-duration="1200">
                    <h1 className="text-5xl font-semibold text-yellow-500 uppercase lg:text-6xl italic ">智游会务 <span className="lg:text-5xl text-4xl text-white">智能会务管理专家</span></h1>
                    <p className="pt-5 leading-relaxed text-2xl font-medium text-white ">高效会议组织管理，一站式数字化解决方案，覆盖会议全流程服务，<br />
                        助力全面提升会议品质与办会效率</p>
                </div>
            </div>
        </div>
    )
}

export default AboutHero
