import React from 'react'
import AboutHero from '../Components/AboutHero'
import AboutIntro from '../Components/AboutIntro'

const About = () => {
  return (
    <>
      <AboutHero />
      <AboutIntro/>

    </>
  )
}

export default About
