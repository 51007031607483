import HeroImage from '../../Assets/Images/svg/undraw_teaching_re_g7e3.svg'
function EventHero() {
    return <div className='w-full h-144 flex bg-yellow-400' >
        <div className='container mx-auto flex items-center justify-between'

        >
            <div
                className="mx-4 py-4"
                data-aos='fade-up'
                data-aos-offset="200"
                data-aos-delay="50"
                data-aos-duration="1800"
                data-aos-once="true">
                <h1 className='text-5xl block'>智游会务新动态</h1>
                <p className='text-lg whitespace-pre-line mt-6'> 随时掌握智游会务最新动态，一起见证智游会务的成长与发展 </p>
            </div>
            <div className="mx-4 py-4">
                <figure className="image">
                    <img className="md:w-[32rem] md:h-96 sm:w-60 sm:h-auto" src={HeroImage} alt="智游会务新动态" />
                </figure>
            </div>
        </div>
    </div>;
}

export default EventHero;
